import { template as template_a180dba3d35447758be61a373b251691 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_a180dba3d35447758be61a373b251691(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
