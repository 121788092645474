import { template as template_d2ee1ef471b44ad88385271ef310ed79 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d2ee1ef471b44ad88385271ef310ed79(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
